import React from 'react';
import { Link } from 'react-router-dom';


const Contact = () => {
  return (
    <div className="content">
        <div className="parent-company">
            <Link to="/">
                <img src="/rwe.png" alt="Company Logo" className="rwe-logo" />
            </Link>
            <h1>Contact Us</h1>
            <p className="slogan">Innovation, Reimagined.</p>
            <hr />
            <p>We’d love to hear from you! Whether you have questions, feedback, or need support, feel free to reach out to us anytime.</p>

            <p>You can contact us via email at:</p>

            <p><a style={{color: "#6A8CC1", fontWeight: "bold", fontStyle: "italic"}} href="mailto:services@reedwalson.com">services@reedwalson.com</a></p>

            <p>Thanks for visiting!</p>
        </div>
    </div>
  );
};

export default Contact;