import React from 'react';
import { Link } from 'react-router-dom';


function Home() {
  return (
    <div className="content">
        <div className="parent-company">
            <Link to="/">
                <img src="/rwe.png" alt="Company Logo" className="rwe-logo" />
            </Link>
            <h1>Enterprise Group</h1>
            <p className="slogan">Innovation, Reimagined.</p>
            <hr />
            <p className="description">Enterprises curating experiences that blend comfortable living spaces, fashion, online shopping, delicious dining options, and cutting-edge tech entertainment—all designed to fuel your passions and elevate your lifestyle. Our mission is to bring the best of comfort, excitement, and innovation together, making every moment more inspiring and enjoyable.</p>
        </div>
        <div className="children-companies">
            <div className="connectors">
                <div className="vertical-connector"></div>
                <div className="horizontal-connector"></div>
                <div className="child-connector"></div>
                <div className="child-connector"></div>
                <div className="child-connector"></div>
            </div>
            <div className="child-company">
                <div className="child-content">
                    <Link to="https://walsonrealty.com">
                        <img src="/wr.png" alt="Walson Realty, LLC Logo" className="wr-logo" />
                    </Link>
                    <h2>Tranquil Residences</h2>
                    <p className="child-description">Serenity, Peace, Comfort</p>
                    {/* <hr style={{margin: "0 auto", marginTop: "30px", marginBottom: "30px", width: "10%"}} />
                    <p className="child-description">Luxury Watches and Accessories</p>
                    <p className="child-description">Luxury Watches and Accessories</p> */}
                </div>
            </div>
            <div className="child-company">
                <div className="child-content">
                    <Link to="https://atainvestmentsllc.com">
                        <img src="/ata.png" alt="ATA Investments, LLC Logo" className="ata-logo" />
                    </Link>
                    <h2>Flavorful Delights <br />Zesty Creations</h2>
                    <p className="child-description">Savory, Satisfying, Fresh</p>
                    {/* <hr style={{margin: "0 auto", marginTop: "30px", marginBottom: "30px", width: "10%"}} />
                    <p className="child-description">Luxury Watches and Accessories</p>
                    <p className="child-description">Luxury Watches and Accessories</p> */}
                </div>
            </div>
            <div className="child-company">
                <div className="child-content">
                    <Link to="https://theinfiniterisecompany.com">
                        <img src="/tirc.png" alt="The Infinite Rise Company, LLC Logo" className="tirc-logo" />
                    </Link>
                    <h2>Seamless Integrations</h2>
                    <p className="child-description">Technological, Vibrant, Exciting</p>
                    {/* <hr style={{margin: "0 auto", marginTop: "30px", marginBottom: "30px", width: "10%"}} />
                    <p className="child-description">Luxury Watches and Accessories</p>
                    <p className="child-description">Luxury Watches and Accessories</p> */}
                </div>
            </div>
        </div>
    </div>
  );
}

export default Home;