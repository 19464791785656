import React from 'react';
import { Link } from 'react-router-dom';


const About = () => {
  return (
    <div className="content">
        <div className="parent-company">
            <Link to="/">
                <img src="/rwe.png" alt="Company Logo" className="rwe-logo" />
            </Link>
            <h1>About Us</h1>
            <p className="slogan">Innovation, Reimagined.</p>
            <hr />
            <div className="aboutus">
                <p>At Reed Walson Enterprises, LLC (RWE), we’re more than just a brand—we’re curators of a lifestyle that blends style, comfort, and innovation. Cultivating Enterprises curating experiences that blend comfortable living spaces, fashion, online shopping, delicious dining options, and cutting-edge tech entertainment—all designed to fuel your passions and elevate your lifestyle. Our mission is to bring the best of comfort, excitement, and innovation together, making every moment more inspiring and enjoyable.</p>

                <p>We’ve set out to reimagine how modern things can be, offering everything from stylish clothing to inspiring living spaces, delicious dining options, and cutting-edge tech entertainment. Our mission is simple: to elevate everyday experiences by catering to desires, not just needs.</p>

                <p>We believe that living well means more than comfort; it’s about creating environments that spark joy and fuel your passions. From tranquil residences designed for peaceful relaxation to zesty culinary creations that awaken the senses, we craft experiences that embody the best of what life has to offer. With every product and service, we aim to inspire, excite, and connect.</p>

                <p>As we continue to grow, we’re committed to staying at the forefront of innovation. Seamless integration across our offerings ensures a fluid and effortless experience for our customers, bringing the finest in fashion, food, tech, and living under one cohesive vision. We’re not just meeting expectations—we’re redefining them.</p>
            </div>
        </div>
    </div>
  );
};

export default About;